






































import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MoreIcon from "@/assets/icons/saxcons/more-linear.svg";
import TickIcon from "@/assets/icons/saxcons/tick-circle-linear.svg";
import { StripePaymentMethod } from "@/mixins/Stripe";
import { StripeCardPaymentMethod } from "@/types/Payment";

@Component({
  name: "stripe-card",
  components: {
    MoreIcon,
    TickIcon
  }
})
export default class StripeCard extends mixins(StripePaymentMethod) {
  @Prop({ required: false, default: false })
  dense!: boolean;

  get stripeCardInfo(): StripeCardPaymentMethod {
    return this.methodInfo as StripeCardPaymentMethod;
  }

  get cardType(): string {
    switch (this.stripeCardInfo.brand) {
      case "union-pay":
        return "unionpay";
      case "jcb":
      case "diners":
      case "discover":
      case "amex":
      case "mastercard":
      case "visa":
        return this.stripeCardInfo.brand;
      default:
        return "unknown";
    }
  }
}
