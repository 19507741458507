





























import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MoreIcon from "@/assets/icons/saxcons/more-linear.svg";
import TickIcon from "@/assets/icons/saxcons/tick-circle-linear.svg";
import { StripePaymentMethod } from "@/mixins/Stripe";
import { StripeBankPaymentMethod } from "@/types/Payment";

@Component({
  name: "stripe-bank-card",
  components: {
    MoreIcon,
    TickIcon
  }
})
export default class StripeBankCard extends mixins(StripePaymentMethod) {
  @Prop({ required: false, default: false })
  dense!: boolean;

  get stripeBankInfo(): StripeBankPaymentMethod {
    return this.methodInfo as StripeBankPaymentMethod;
  }
}
